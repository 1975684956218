<template>
  <div class="p-grid pending-approval">
    <div class="p-col-12 p-p-0">
      <view-switcher
        :layout="layout"
        :loading="loading"
        :length="profiles.length"
        @toggleView="toggleView"
      />
      <div v-if="profiles.length !== 0 || loading">
        <list-view
          v-if="layout === 'list'"
          :profiles="profiles"
          :loading="loading"
          :load-profiles="loadProfiles"
          :profile-number="profileNumber"
          @updateShortlist="shortlistProfile"
          @sendinterest="sendinterest"
        />
        <grid-view
          v-else
          :profiles="profiles"
          :loading="loading"
          :load-profiles="loadProfiles"
          :profile-number="profileNumber"
          @updateShortlist="shortlistProfile"
          @sendinterest="sendinterest"
        />
      </div>
      <div v-else>
        <empty-mails
          :empty-text="'No mutual profiles found'"
          :img="authUser.gender === 'male' ? '/bride.png' : '/groom.png'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GridView from '@/components/views/GridView.vue';
import ListView from '@/components/views/ListView.vue';
import { mapGetters } from 'vuex';
import EmptyMails from '@/components/EmptyMails.vue';
import { profileInterestMixin } from '@/mixins/profileInterestMixin.js';
import { mailboxMixin } from '@/mixins/mailboxMixin';
import ViewSwitcher from '@/utilities/ViewSwitcher.vue';

export default {
  components: {
    GridView,
    ListView,
    EmptyMails,
    ViewSwitcher,
  },
  mixins: [profileInterestMixin, mailboxMixin],
  data() {
    return {
      reqUrl: 'users/fetch_mutual_users',
    };
  },
  computed: {
    ...mapGetters(['authUser']),
  },
};
</script>
